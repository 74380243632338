<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">Klasifikasi Surat Dokumen</h3>
            <hr class="m-t-0 m-b-40">

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">
                    Induk Klasifikasi
                  </label>
                  <div class="col-md-8">
                    <treeselect 
                    v-model="payload.klasifikasi" 
                    :options="indukklasifikasi" 
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input v-model="payload.code_classification" name="code_classification" required type="text" label="Kode Klasifikasi"/>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input v-model="payload.name_classification" name="name_classification" required type="text" label="Nama Klasifikasi"/>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">
                    Retensi Active
                  </label>
                  <div class="col-md-8">
                    <select v-model="payload.retensi_aktif" :class="['form-control', {'text-right': !isMobile}, 'col-md-4']">
                       <option disabled value="">Please select one</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                      <option>13</option>
                      <option>14</option>
                      <option>15</option>
                      <option>16</option>
                      <option>17</option>
                      <option>18</option>
                      <option>19</option>
                      <option>20</option>
                    </select>
                    <label :class="['control-label', {'text-left': !isMobile}, 'col-md-4']">
                    Tahun
                  </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">
                    Retensi Inactive
                  </label>
                  <div class="col-md-8">
                    <select v-model="payload.retensi_inaktif" :class="['form-control', {'text-right': !isMobile}, 'col-md-4']">
                       <option disabled value="">Please select one</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                      <option>13</option>
                      <option>14</option>
                      <option>15</option>
                      <option>16</option>
                      <option>17</option>
                      <option>18</option>
                      <option>19</option>
                      <option>20</option>
                    </select>
                    <label :class="['control-label', {'text-left': !isMobile}, 'col-md-4']">
                    Tahun
                  </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">
                    Status Aksi Selanjutnya
                  </label>
                  <div class="col-md-8">
                    <treeselect 
                    v-model="payload.status_aksi_selanjutnya" 
                    :options="statusaksiselanjutnya" 
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>
          <hr>
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button type="submit" class="btn btn-success" title="Submit">Submit</button>&nbsp;
                    <button type="button" @click="goBack()" class="btn btn-inverse" title="Cancel">Cancel</button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Multiselect from "vue-multiselect";
// import the component
// import Treeselect from '@riophae/vue-treeselect';
// import the styles
// import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Input from "@/components/input/input";
import axios from "axios";

export default {
  components: {
    Input,
    RotateSquare5,
    Multiselect,
    // Treeselect,
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.clasificationdoc;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    // isInvalidKlasifikasi(){
    //   return (
    //     this.dropdownKlasifikasi.isTouched &&
    //     this.dropdownKlasifikasi.value.length === 0
    //   );
    // },
    // isInvalidStatusRetensi(){
    //   return (
    //     this.dropdownStatusRetensi.isTouched &&
    //     this.dropdownStatusRetensi.value.length === 0
    //   );
    // },
    isinvalidTipe() {
      return this.isTouched && this.payload.valueTipe.length === 0;
    }
  },
  data() {
    return {
      value: null,
      // indukklasifikasi: this.$store.state.clasificationdoc.items.treeklasifikasi,
      indukklasifikasi: [],
      statusaksiselanjutnya: [
        {
          id: '1',
          label: 'Dinilai Kembali',
        },
        {
          id: '2',
          label: 'Dimusnahkan'
        },
        {
          id: '3',
          label: 'Dipermanenkan'
        },
      ],
      payload: {
        klasifikasi: [],
        status_aksi_selanjutnya: [],
        code_classification: "",
        name_classification: "",
        retensi_aktif: "",
        retensi_inaktif: "",
        // statusretensi: [],
      },
      dropdownKlasifikasi: {
        isTouched: false,
        klasifikasiList: [],
        value: [],
        isLoadingKlasifikasi: false
      },
      dropdownStatusRetensi: {
        isTouched: false,
        statusretensiList: [],
        value: [],
        isLoadingStatusRetensi: false
      },
      isDisabled: false,
      optionTipe: [],
    };
  },
  async mounted() {
    this.initialize();
    this.getAllReference();
    this.treeKlasifikasi();
    // this.tipeFind("");
  },

  methods: {
    treeKlasifikasi(){
      axios.get(`/clasificationdoc/get_list_klasifikasi`).then(res=>{
        this.indukklasifikasi = res.data.data;
      })
    },
    async initialize() {
      try {
        if (this.editMode) {
          axios
            .get(`/clasificationdoc/${this.$route.params.id}`)
            .then(response => {
              const state = {
                loaded: true
              };
              this.$store.commit("clasificationdoc/STATE", state);
              this.edit(response.data.data);
            });
        } else {
          this.$store.dispatch("clasificationdoc/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    getAllReference(){
      var query = "";
      // this.klasifikasi(query);
      // this.statusretensi(query);
    },
    edit(data) {
      this.payload = {
        parent_id: data.klasifikasi,
        code_classification: data.code_classification,
        name_classification: data.name_classification,
        retensi_aktif: data.retensi_aktif,
        retensi_inaktif: data.retensi_inaktif,
        status_aksi_selanjutnya: data.status_aksi_selanjutnya,
        // waktu_retensi: data.retensi,
        // status_retensi: data.statusretensi,
      };
    },
    goBack() {
      this.$store.dispatch("clasificationdoc/onCancel");
    },
    onTouchKlasifikasi() {
      this.dropdownKlasifikasi.isTouched = true;
    },
    onTouchStatusRetensi() {
      this.dropdownStatusRetensi.isTouched = true;
    },
    onTouch() {
      this.isTouched = true;
      this.isTouchedInduk = true;
      this.isTouchedTujuan = true;
    },
    onChangeKlasifikasi(value) {
      this.dropdownKlasifikasi.value = value;
    },
    onChangeStatusRetensi(value) {
      this.dropdownStatusRetensi.value = value;
    },
    onChangeTipe(value) {
      this.payload.valueTipe = value;
    },
    // klasifikasi(query) {
    //   this.dropdownKlasifikasi.isLoadingKlasifikasi = true;
    //   axios
    //     .get(`clasificationdoc/list?s=${query}`)
    //     .then(res => {
    //       this.dropdownKlasifikasi.klasifikasiList = res.data.items;
    //       this.dropdownKlasifikasi.isLoadingKlasifikasi = false;
    //     })
    //     .catch(err => {
    //       if (err.response.status === 401) {
    //         store.commit("auth/LOGOUT");
    //       }
    //     });
    // },
    // statusretensi(query) {
    //   this.dropdownStatusRetensi.isLoadingStatusRetensi = true;
    //   axios
    //     .get(`clasificationdoc/listtree?s=${query}`)
    //     .then(res => {
    //       this.dropdownStatusRetensi.statusretensiList = res.data.items;
    //       this.dropdownStatusRetensi.isLoadingStatusRetensi = false;
    //     }).catch(err => {
    //       if (err.response.status === 401) {
    //         store.commit("auth/LOGOUT");
    //       }
    //     });
    // },
    // tipeFind(query) {
    //   this.isLoading = true;
    //   axios
    //     .get(`typeSurat/list?s=${query}`)
    //     .then(res => {
    //       this.optionTipe = res.data.items;
    //       this.isLoading = false;
    //     })
    //     .catch(err => {
    //       if (err.response.status === 401) {
    //         this.$store.commit("auth/LOGOUT");
    //       }
    //     });
    // },
    submit() {
      const payload = {
        parent_id: parseInt(this.payload.klasifikasi),
        status_aksi_selanjutnya: parseInt(this.payload.status_aksi_selanjutnya),
        // status_rentensi: parent_id(this.payload.statusrentensi.value)
      };
      const data = JSON.stringify(this.payload);
      this.$validator.validateAll().then(success => {
        if (success) {
          const state = {
            loaded: false
          };
          if (this.editMode) {
            this.$store.dispatch("clasificationdoc/submitEdit", {
              data,
              id: this.$route.params.id
            });
          } else {
            this.$store.dispatch("clasificationdoc/submitAdd", data);
          }
        }
      });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
.normal-background {
  background-color: #fff !important;
}
</style>

